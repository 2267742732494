import React, { useState } from 'react';
import './toggleswitch.css';



const ToggleSwitch = ({ isOn, toggleSwitch }) => {
  return (
    <div className={`toggle-switch ${isOn ? 'on' : 'off'}`} onClick={toggleSwitch}>
      <div className="slider" />
    </div>
  );
};

export default ToggleSwitch;
