import './css/Home.css';
import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import P1 from '../assets/P1.jpeg'
import P2 from '../assets/P2.png'
import P4 from '../assets/P4.jpeg'
import P5 from '../assets/P5.jpeg'

const Home = () => {
  return (
      <div className='homeContainer'>
        <div className="stl_ stl_02">
          <div className="stl_view">
            <div className="stl_05">
              <div className="stl_01" style={{left: '8.125em', top: '12.0019em'}}>
              <span className="stl_12 stl_08 stl_09" style={{wordSpacing: '-0em'}}>
                OLAB PLUS 30 ANS À VOTRE SERVICE! &nbsp;
              </span>
              </div>
              <div className="stl_01" style={{left: '12.5375em', top: '19.3521em'}}>
                <span className="stl_13 stl_14 stl_09"></span>
              </div>
              <div className="stl_01" style={{left: '29.5722em', top: '19.3521em'}}>
                <span className="stl_13 stl_14 stl_09"></span>
              </div>
              <div className="stl_01" style={{left: '47.232em', top: '19.3521em'}}>
                <span className="stl_13 stl_14 stl_09"></span>
              </div>
              <div className="stl_01" style={{left: '9.8701em', top: '22.4915em'}}>
                <span className="stl_15 stl_16 stl_09">S'ENREGISTRER GRATUITEMENT &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '46.3827em', top: '22.4915em'}}>
                <span className="stl_15 stl_16 stl_09">SUIVI DE VOS COMMANDES &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '29.3738em', top: '22.5311em'}}>
              <span className="stl_17 stl_08 stl_18" style={{wordSpacing: '-0.019em'}}>
                COMMANDEZ EN LIGNE &nbsp;
              </span>
              </div>
              <div className="stl_01" style={{left: '26.7222em', top: '24.3093em'}}>
                <a href="http://olab-plus.ca/commandez/" target="_blank">
                  <span className="stl_19 stl_16 stl_09">Vous pouvez faire vos commandes en toute &nbsp;</span>
                </a>
              </div>
              <div className="stl_01" style={{left: '26.7222em', top: '25.4701em'}}>
                <span className="stl_19 stl_16 stl_09">sécurité, en choisissant des produits de haute &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '26.7222em', top: '26.6308em'}}>
                <span className="stl_19 stl_16 stl_09">qualité &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '8.75em', top: '24.9343em'}}>
                <a href="http://olab-plus.ca/mon-compte/" target="_blank">
                  <span className="stl_19 stl_16 stl_09">Vous devez créer votre compte, c'est gratuit, &nbsp;</span>
                </a>
              </div>
              <div className="stl_01" style={{left: '8.75em', top: '26.0951em'}}>
                <span className="stl_19 stl_16 stl_09">veuillez entrer votre nom, courriel et choisir un &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '8.75em', top: '27.2558em'}}>
                <span className="stl_19 stl_16 stl_09">mot de passe &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '44.6945em', top: '24.9343em'}}>
                <a href="http://olab-plus.ca/mon-compte/" target="_blank">
                  <span className="stl_19 stl_16 stl_09">Vous pouvez faire le suivi de vos commandes &nbsp;</span>
                </a>
              </div>
              <div className="stl_01" style={{left: '44.6945em', top: '26.0951em'}}>
                <span className="stl_19 stl_16 stl_09">et vous pouvez voir l'historique de vos &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '44.6945em', top: '27.2558em'}}>
                <span className="stl_19 stl_16 stl_09">anciennes commandes &nbsp;</span>
              </div>
              <div className="stl_01" style={{left: '28.0722em', top: '30.0908em'}}>
              <span className="stl_20 stl_08 stl_21" style={{wordSpacing: '-0em'}}>
                PLUS DE DÉTAILS &nbsp;
              </span>
              </div>
              <div className="stl_01" style={{left: '10.1em', top: '30.0908em'}}>
              <span className="stl_20 stl_08 stl_21" style={{wordSpacing: '-0em'}}>
                PLUS DE DÉTAILS &nbsp;
              </span>
              </div>
              <div className="stl_01" style={{left: '46.0445em', top: '30.0908em'}}>
              <span className="stl_20 stl_08 stl_21" style={{wordSpacing: '0em'}}>
                PLUS DE DÉTAILS &nbsp;
              </span>
              </div>
            </div>
          </div>
        </div>

        <Container>
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <h5> 
                <b>
                Fier partenaire des cliniques indépendants du Canada
                </b>
                </h5>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="footer-logos">
            <Col xs="3" className="footer-logo">
              <img src={P1} style={{height: '60px'}} className='logoheader' alt='partner1'/>
            </Col>
            <Col xs="4" className="footer-logo">
              <img src={P2} style={{height: '60px'}} className='logoheader' alt='partner2'/>
            </Col>
            <Col xs="2" className="footer-logo">
              <img src={P4} style={{height: '60px'}} className='logoheader' alt='partner4'/>
            </Col>
            <Col xs="3" className="footer-logo">
              <img src={P5} style={{height: '60px'}} className='logoheader' alt='partner5'/>
            </Col>
          </Row>
        </Container>

        <footer className="footer" style={{ backgroundColor: 'black', padding: '1em' , width : '100%' }}>
          <Container>
            <Row>
              <Col xs="12" className="text-center">
                <p style={{color: 'white', margin: 0}}>OLABPLUS Inc. 2500 CHEMIN DES PRAIRIES, BROSSARD, (QC) J4Y 2X2 CANADA</p>

                <p style={{color: 'white', margin: 0}}>T. 450-466-2862 T. 1-800-761-2862 F. 450-656-6829 F. 450-486-3827</p>

                <p style={{color: 'white', margin: 0}}>CONTACT@OLABPLUS.CA</p>

                <p style={{color: 'white', margin: 0}}>© OLABPLUS 1987 - 2024. Tous droits réservés</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
  );
};

export default Home;
