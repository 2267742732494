import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabPane, TabContent, Button, Modal, ModalBody, Label, Input, Form, FormGroup } from 'reactstrap';

import './css/ModalAuth.css';

function ModalAuth({ handleAuth, handleLogout, isModalOpenAuth, setisModalOpenAuth, auth }) {
  const [activeTab, setActiveTab] = useState('1');
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Modal isOpen={isModalOpenAuth} toggle={() => setisModalOpenAuth(!isModalOpenAuth)}>
      <ModalBody className="ModalAuthBody">
        <Nav tabs className="NavTabs">
          <NavItem>
            <NavLink onClick={() => toggleTab('1')}
              style={{ backgroundColor: activeTab === '1' ? '#F7C873' : 'transparent' }}>
              Connexion
            </NavLink>
          </NavItem>
          {auth?.user?.accountType == 'admin' && <NavItem>
            <NavLink onClick={() => toggleTab('2')}
              style={{ backgroundColor: activeTab === '2' ? '#F7C873' : 'transparent' }}>
              Créer Compte
            </NavLink>
          </NavItem>}
        </Nav>

        <TabContent activeTab={activeTab} className="tab-content">
          <TabPane tabId="1">
            {(activeTab == 1) &&
              <Form onSubmit={(e) => handleAuth(e, false)}>
                <FormGroup className="form-group">
                  <Label for="code" className="form-label">Compte</Label>
                  <Input type="text" name="code" id="code" placeholder="Entrez votre numéro de compte" className="form-input" />
                </FormGroup>
                <FormGroup className="form-group">
                  <Label for="password" className="form-label">Password</Label>
                  <Input type="password" name="password" id="password" placeholder="Entrez votre password" className="form-input" />
                </FormGroup>
                <div className="d-flex justify-content-between">
                  <Button type="submit" className="form-button">Connexion</Button>
                  <Button className="form-button" onClick={handleLogout} >Déconnexion</Button>
                </div>
              </Form>}
          </TabPane>
          {activeTab == 2 && auth?.user?.accountType == 'admin' && <TabPane tabId="2">
            <Form onSubmit={(e) => handleAuth(e, true)}>
              <FormGroup className="form-group">
                <Label for="code" className="form-label">Compte</Label>
                <Input type="text" name="code" id="code" placeholder="Entrez le numéro de compte" className="form-input" />
              </FormGroup>
              <FormGroup className="form-group">
                <Label for="name" className="form-label">Nom Client</Label>
                <Input type="text" name="name" id="name" placeholder="Entrez le nom client" className="form-input" />
              </FormGroup>
              <FormGroup className="form-group">
                <Label for="email" className="form-label">Email</Label>
                <Input type="email" name="email" id="email" placeholder="Entrez l'email" className="form-input" />
              </FormGroup>
              <FormGroup className="form-group">
                <Label for="password" className="form-label">Password</Label>
                <Input type="password" name="password" id="password" placeholder="Entrez un password" className="form-input" />
              </FormGroup>
              <FormGroup className="form-group">
                <Label for="address" className="form-label">Adresse</Label>
                <Input type="textarea" name="address" id="address" placeholder="Entrez une adresse" className="form-input" />
              </FormGroup>
              <Button type="submit" className="form-button">Créer</Button>
            </Form>
          </TabPane>}
        </TabContent>
      </ModalBody>
    </Modal>
  );
}

export default ModalAuth;
