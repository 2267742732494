import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { Auth } from './auth';

import { createAction, createReducer } from '@reduxjs/toolkit'

const increment = createAction('counter/increment')
const decrement = createAction('counter/decrement')
const incrementByAmount = createAction('counter/incrementByAmount')

const initialState = { value: 0 }

const counterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(increment, (state, action) => {
      state.value++
    })
    .addCase(decrement, (state, action) => {
      state.value--
    })
    .addCase(incrementByAmount, (state, action) => {
      state.value += action.payload
    })
})

export default configureStore({
  reducer: { counter: counterReducer, auth: Auth },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    { serializableCheck: false, immutableCheck: false }
  ).concat(logger)

})
